<template>
    <a-modal title="选择角色" v-model:visible="visible" :width="468" @ok="finish" destroyOnClose>
        <div style="display:flex;justify-content:space-between;align-items:center;">
            <a-checkbox-group class="list" style="padding:8px;width:160px" v-model:value="select_role_id_list" v-if="multiple">
                <a-checkbox style="margin:0" v-for="role in could_select_role_list" :key="role.id" :value="role.id" @click="select_role_id = role.id">{{ role.name }}</a-checkbox>
            </a-checkbox-group>
            <a-radio-group class="list" style="padding:8px;width:160px" v-model:value="select_role_id_list[0]" v-else>
                <a-radio style="margin:0" v-for="role in could_select_role_list" :key="role.id" :value="role.id" @click="select_role_id = role.id">{{ role.name }}</a-radio>
            </a-radio-group>
            <DoubleRightOutlined />
            <div class="list" style="width:220px;background:#f6f6f6">
                <!-- 没有点选角色 -->
                <a-empty description="点击左侧角色查看详情" v-if="select_role_id == 0" style="flex-grow:1;display:flex;flex-direction:column;justify-content:center" />
                <!-- 选择了角色 -->
                <div v-else style="height:100%;display:flex;flex-direction:column;">
                    <!-- 角色描述为空，显示 icon+暂无描述 -->
                    <div v-if="select_role_remark == ''" style="padding:5px;text-align:center"><InfoCircleOutlined style="margin-right:5px" />暂无描述</div>
                    <!-- 角色描述不为空，显示描述 -->
                    <div v-else style="padding:12px;line-height:16px" title="角色描述">{{ select_role_remark }}</div>
                    <!-- 角色的用户列表为空，显示 icon+暂无角色 -->
                    <div v-if="select_role_user_list.length == 0" style="border-top: 1px solid #e6e6e6;flex-grow:1;display:flex;flex-direction:column;justify-content:center">
                        <div style="padding:5px;text-align:center"><InfoCircleOutlined style="margin-right:5px" />暂无角色</div>
                    </div>
                    <!-- 角色用户列表不为空，显示用户列表 -->
                    <div v-else style="flex-grow:1;display:flex;flex-direction:column;justify-content:flex-start">
                        <a-table :data-source="select_role_user_list" :pagination="false" :showHeader="false" size="small" bordered>
                            <a-table-column key="name" data-index="name">
                                <template #default="scoped">
                                    <a-avatar :src="scoped.record.avatar" :size="24"></a-avatar>
                                    <span style="margin-left:10px">{{ scoped.record.name }}</span>
                                </template>
                            </a-table-column>
                        </a-table>
                    </div>
                </div>
            </div>
        </div>
    </a-modal>
</template>

<script>
/**
 * 该组件使用方法
 * 1.在父组件中为该组件设置属性：ref='xxx'
 * 2.在父组件中使用this.$refs['xxx'].start()调用该子组件的start方法开始选择
 * 3.监听finish事件，返回的参数为选择的角色id列表，多选时如：[1,2,3]，单选情况也为列表，如：[4]
 */
import { DoubleRightOutlined, InfoCircleOutlined } from '@ant-design/icons-vue';
export default {
    components: {
        DoubleRightOutlined,
        InfoCircleOutlined,
    },
    name: 'SelectRoleModal',
    data() {
        return {
            visible: false,
            // 可供用于选择的角色列表
            could_select_role_list: [],
            // 已选择的角色id列表
            select_role_id_list: [],
            // 当前点选的角色，用于数据在右侧临时预览
            select_role_id: 0,
            select_role_remark: '',
            select_role_user_list: [],
        };
    },
    props: {
        multiple: {
            type: Boolean,
            default: true,
        },
        allowNull: {
            type: Boolean,
            default: true,
        },
    },
    watch: {
        select_role_id(role_id) {
            this.could_select_role_list.forEach((role) => {
                if (role.id == role_id) {
                    this.select_role_remark = role.remark;
                    this.select_role_user_list = [];
                    // console.log(role.user_list);
                    role.user_list.forEach((user_id) => {
                        this.select_role_user_list = [];
                        this.$api('getUserInfo', { user_id }).then((res) => {
                            res.key = user_id.toString();
                            this.select_role_user_list.push(res);
                        });
                    });
                }
            });
        },
    },
    methods: {
        /**
         * @param {[String|Number]} select_role_id_list 当前已选中的角色id列表，不传入时默认为空
         * @param {[String|Number]} could_select_role_id_list 可用于选择的角色id列表，不传入时默认为全部角色
         */
        async start(select_role_id_list = [], could_select_role_id_list = undefined) {
            // 用于判断元素是否为数字或字符串数字的函数，[true：0 1 '2' 3.4 '5.6']
            let f1 = (v) => {
                return typeof v === 'number' || (typeof v === 'string' && !isNaN(Number(v)));
            };

            // 将符合f1条件的转为字符串整形数字，[1=>'1'] ['2.3'=>'2'] [4.5=>'4']
            let f2 = (v) => {
                return parseInt(v).toString();
            };

            // 判断select_role_id_list是否合规
            if (!(select_role_id_list instanceof Array)) {
                return console.error('传入的select_role_id_list不是数组');
            }
            // 转化select_role_id_list中的元素
            if (select_role_id_list.every(f1)) {
                select_role_id_list = select_role_id_list.map(f2);
            } else {
                return console.error('传入的select_role_id_list列表中有不合规的元素');
            }

            // 判断是否传入could_select_role_id_list
            if (could_select_role_id_list !== undefined) {
                // 判断could_select_role_id_list是否合规
                if (!(could_select_role_id_list instanceof Array)) {
                    return console.error('传入的could_select_role_id_list不是数组');
                }
                // 转化could_select_role_id_list中的元素
                if (could_select_role_id_list.every(f1)) {
                    could_select_role_id_list = could_select_role_id_list.map(f2);
                } else {
                    return console.error('传入的could_select_role_id_list列表中有不合规的元素');
                }
            }

            let all_role_list = await this.$api('getRoleList');
            // 如果没有传入可供选择的角色列表，就将所有角色设置添加进可选择列表
            if (could_select_role_id_list === undefined) {
                could_select_role_id_list = all_role_list.user_list.map((user) => {
                    return user.id.toString();
                });
            }

            this.could_select_role_list = [];
            all_role_list.role_list.forEach((role) => {
                // role.label = role.name;
                role.id = role.id.toString();
                if (could_select_role_id_list.indexOf(role.id.toString()) != -1) {
                    this.could_select_role_list.push(role);
                }
            });

            this.select_role_id_list = [];
            select_role_id_list.forEach((user_id) => {
                if (could_select_role_id_list.indexOf(user_id) != -1) {
                    this.select_role_id_list.push(user_id);
                }
            });
            // 单选模式下，如果已经选择大于1人时，仅取第一人
            if (!this.multiple && this.select_role_id_list.length > 1) {
                this.select_role_id_list = [this.select_role_id_list[0]];
            }
            this.select_role_id = 0;
            this.visible = true;
        },
        finish() {
            let temp = [];
            this.select_role_id_list.forEach((item) => {
                temp.push(parseInt(item));
            });
            this.$emit('finish', temp);
            this.visible = false;
        },
    },
};
</script>

<style scoped>
.list {
    min-height: 180px;
    max-height: 400px;
    overflow: auto;
    background: #f3f3f3;
    display: flex;
    flex-direction: column;
    /* flex-grow: 1; */
    align-self: stretch;
}
</style>
