<template>
    <a-modal v-model:visible="visible" title="选择成员" destroyOnClose width="588px" @ok="finish">
        <a-button-group style="display:flex;justify-content:space-between">
            <a-button type="primary" class="flex-button" @click="selectUser" v-if="select_member_type.user"><UserOutlined />选择用户</a-button>
            <a-button type="primary" class="flex-button" @click="selectRole" v-if="select_member_type.role"><TeamOutlined />选择角色</a-button>
            <a-button type="primary" class="flex-button" @click="selectDepartment" v-if="select_member_type.department"><ApartmentOutlined />选择部门</a-button>
        </a-button-group>
        <div style="margin-top:10px">
            <strong>当前已选择：</strong>
            <div style="background:#f3f3f3;min-height:160px;max-height:300px;overflow:auto" :class="multiple ? 'multiple' : 'single'">
                <a-empty style="margin:10px auto;" v-if="select_member_list.length == 0" />
                <!-- 单选 -->
                <div v-if="!multiple && select_member_list[0]">
                    <!-- 用户 -->
                    <a-tag v-if="select_member_list[0].type == 'user' && user_info_list[select_member_list[0].id]" :color="{ 男: 'blue', 女: 'pink' }[user_info_list[select_member_list[0].id].gender]" closable @close="select_member_list = []">
                        <template #icon>
                            <a-avatar :size="48" style="margin:8px" :src="user_info_list[select_member_list[0].id].avatar" />
                        </template>
                        <span style="font-size:16px;margin:4px 16px;letter-spacing:4px">{{ user_info_list[select_member_list[0].id].name }}</span>
                    </a-tag>
                    <!-- 角色 -->
                    <a-tag v-if="select_member_list[0].type == 'role' && role_list[select_member_list[0].id]" color="orange" closable @close="select_member_list = []">
                        <template #icon>
                            <a-avatar :size="48" style="margin:8px;background:#f56a00">
                                <template #icon>
                                    <TeamOutlined />
                                </template>
                            </a-avatar>
                        </template>
                        <span style="font-size:16px;margin:4px 16px;letter-spacing:4px">{{ role_list[select_member_list[0].id].name }}</span>
                    </a-tag>
                    <!-- 部门 -->
                    <a-tag v-if="select_member_list[0].type == 'department' && department_list[select_member_list[0].id]" color="green" closable @close="select_member_list = []">
                        <template #icon>
                            <a-avatar :size="48" style="margin:8px;background:#87d068">
                                <template #icon>
                                    <ApartmentOutlined />
                                </template>
                            </a-avatar>
                        </template>
                        <span style="font-size:16px;margin:4px 16px;letter-spacing:4px">{{ department_list[select_member_list[0].id].name }}</span>
                    </a-tag>
                </div>
                <!-- 多选 -->
                <div v-else v-for="(member, index) in select_member_list" :key="member.key">
                    <!-- 用户 -->
                    <a-tag v-if="member.type == 'user' && user_info_list[member.id]" style="margin-top:10px" closable @close="select_member_list.splice(index, 1)" :color="{ 男: 'blue', 女: 'pink' }[user_info_list[member.id].gender]">
                        <template #icon>
                            <a-avatar :size="28" style="margin:3px 0px" :src="user_info_list[member.id].avatar" />
                        </template>
                        {{ user_info_list[member.id].name }}
                    </a-tag>
                    <!-- 角色 -->
                    <a-tag v-if="member.type == 'role' && role_list[member.id]" style="margin-top:10px" closable @close="select_member_list.splice(index, 1)" color="orange">
                        <template #icon>
                            <a-avatar :size="28" style="margin:3px 0px;background:#f56a00">
                                <template #icon>
                                    <TeamOutlined />
                                </template>
                            </a-avatar>
                        </template>
                        {{ role_list[member.id].name }}
                    </a-tag>
                    <!-- 部门 -->
                    <a-tag v-if="member.type == 'department' && department_list[member.id]" style="margin-top:10px" closable @close="select_member_list.splice(index, 1)" color="green">
                        <template #icon>
                            <a-avatar :size="28" style="margin:3px 0px;background:#87d068">
                                <template #icon>
                                    <ApartmentOutlined />
                                </template>
                            </a-avatar>
                        </template>
                        {{ department_list[member.id].name }}
                    </a-tag>
                </div>
            </div>
        </div>
        <SelectUserModal ref="SelectUserModal" :multiple="multiple" @finish="finishUserSelect" />
        <SelectRoleModal ref="SelectRoleModal" :multiple="multiple" @finish="finishRoleSelect" />
        <SelectDepartmentModal ref="SelectDepartmentModal" :multiple="multiple" @finish="finishDepartmentSelect" />
    </a-modal>
</template>

<script>
/**
 * 该组件使用方法
 * 1.在父组件中为该组件设置属性：ref='xxx'
 * 2.在父组件中使用this.$refs['xxx'].start()调用该子组件的start方法开始选择
 * 3.监听finish事件，返回的参数为选择的成员列表，多选时如：[{type:'user',id:1},{type:'role',id:2}]，单选情况也为列表，如：[{type:'department',id:3}]
 */
import { UserOutlined, TeamOutlined, ApartmentOutlined, CloseOutlined } from '@ant-design/icons-vue';
import SelectUserModal from './SelectUser';
import SelectRoleModal from './SelectRole';
import SelectDepartmentModal from './SelectDepartment';
export default {
    name: 'SelectMemberModal',
    components: {
        UserOutlined,
        TeamOutlined,
        ApartmentOutlined,
        CloseOutlined,
        SelectUserModal,
        SelectRoleModal,
        SelectDepartmentModal,
    },
    data() {
        return {
            visible: false,
            // 可以进行选择的成员
            could_select_member: {
                could_select_user_id_list: [],
                could_select_role_id_list: [],
                could_select_department_id_list: [],
            },
            // 元素示例：{type: 'user', id: 0}
            select_member_list: [],
            select_member_type: { user: true, role: true, department: true },
            user_info_list: [],
            role_list: [],
            department_list: [],
        };
    },
    watch: {
        select_member_list(value) {
            value.forEach(async (member) => {
                if (member.type == 'user' && this.user_info_list[member.id] == undefined) {
                    this.user_info_list[member.id] = await this.$api('getUserInfo', { user_id: member.id });
                }
                if (member.type == 'role' && this.role_list[member.id] == undefined) {
                    let role_list = (await this.$api('getRoleList')).role_list;
                    role_list.forEach((role) => {
                        this.role_list[role.id] = role;
                    });
                }
                if (member.type == 'department' && this.department_list[member.id] == undefined) {
                    let department_list = (await this.$api('getDepartmentList')).department_list;
                    department_list.forEach((department) => {
                        this.department_list[department.id] = department;
                    });
                }
            });
        },
    },
    props: {
        multiple: {
            type: Boolean,
            default: true,
        },
        allowNull: {
            type: Boolean,
            default: true,
        },
        onFinish: {
            type: Function,
        },
    },
    methods: {
        /**
         * @param {Object[]} select_member_list
         * @param {String} select_member_list[].type 成员类型，分为user,role,department
         * @param {Number} select_member_list[].id  对应的成员id
         * @param {Object} args 可选参数
         * @param {[String]} args.could_select_type 支持选择的成员类型，不传入时默认为全类型
         * @param {[Number|String]} args.could_select_user_id_list 可用于选择的用户id列表，不传入时默认为全部
         * @param {[Number|String]} args.could_select_role_id_list 可用于选择的角色id列表，不传入时默认为全部
         * @param {[Number|String]} args.could_select_department_id_list 可用于选择的部门id列表，不传入时默认为全部
         * @return
         */
        start: async function(select_member_list = [], { could_select_type = ['user', 'role', 'department'], could_select_user_id_list = undefined, could_select_role_id_list = undefined, could_select_department_id_list = undefined } = {}) {
            this.select_member_list.user = could_select_type.indexOf('user') != -1;
            this.select_member_list.role = could_select_type.indexOf('role') != -1;
            this.select_member_list.department = could_select_type.indexOf('department') != -1;
            let could_select_member_range_list = await this.$api('getCouldSelectMemberRange');
            // 用于判断select_member_list中的元素是否符合条件
            let f1 = (v) => {
                let type = Boolean(v) && typeof v.type === 'string' && ['user', 'role', 'department'].indexOf(v.type) != -1;
                let id = Boolean(v) && (typeof v.id === 'number' || (typeof v.id === 'string' && !isNaN(Number(v.id))));
                return type && id && could_select_member_range_list[`${v.type}_range_list`].indexOf(parseInt(v.id)) != -1;
            };
            // 用于转换select_member_list中的元素，确保后续代码中的数据一致性
            let f2 = (v) => {
                return { type: v.type, id: parseInt(v.id), key: v.type + v.id };
            };
            // 用于判断could_select_xxxx_id_list等几个列表的的元素是否符合条件
            let f3 = (v) => {
                return (typeof v === 'number' || (typeof v === 'string' && !isNaN(Number(v)))) && parseInt(v);
            };
            // 用于转换could_select_xxxx_id_list等几个列表的元素
            let f4 = (v) => {
                return parseInt(v);
            };
            // 判断select_member_list是有元素满足条件，有的话则将所有满足条件的进行转换
            if (select_member_list.constructor == Array && (select_member_list.length == 0 || select_member_list.some(f1))) {
                select_member_list = select_member_list.filter(f1).map(f2);
            } else {
                console.error('参数select_member_list中的元素格式不正确', select_member_list);
                return;
            }
            // 判断could_select_user_id_list是有元素满足条件，有的话则将所有满足条件的进行转换
            if (could_select_user_id_list !== undefined) {
                if (could_select_user_id_list.constructor == Array && (could_select_user_id_list.length == 0 || could_select_user_id_list.some(f3))) {
                    could_select_user_id_list = could_select_user_id_list.filter(f3).map(f4);
                } else {
                    console.error('参数could_select_user_id_list中的元素格式不正确', could_select_user_id_list);
                    return;
                }
            } else {
                could_select_user_id_list = could_select_member_range_list.user_range_list;
            }
            // 判断could_select_role_id_list是有元素满足条件，有的话则将所有满足条件的进行转换
            if (could_select_role_id_list !== undefined) {
                if (could_select_role_id_list.constructor == Array && (could_select_role_id_list.length == 0 || could_select_role_id_list.some(f3))) {
                    could_select_role_id_list = could_select_role_id_list.filter(f3).map(f4);
                } else {
                    console.error('参数could_select_role_id_list中的元素格式不正确', could_select_role_id_list);
                    return;
                }
            } else {
                could_select_role_id_list = could_select_member_range_list.role_range_list;
            }
            // 判断could_select_department_id_list是有元素满足条件，有的话则将所有满足条件的进行转换
            if (could_select_department_id_list !== undefined) {
                if (could_select_department_id_list.constructor == Array && (could_select_department_id_list.length == 0 || could_select_department_id_list.some(f3))) {
                    could_select_department_id_list = could_select_department_id_list.filter(f3).map(f4);
                } else {
                    console.error('参数could_select_department_id_list中的元素格式不正确', could_select_department_id_list);
                    return;
                }
            } else {
                could_select_department_id_list = could_select_member_range_list.department_range_list;
            }
            this.select_member_list = select_member_list;
            this.could_select_user_id_list = could_select_user_id_list;
            this.could_select_role_id_list = could_select_role_id_list;
            this.could_select_department_id_list = could_select_department_id_list;
            this.visible = true;
        },

        finish() {
            this.$emit('finish', this.select_member_list);
            this.visible = false;
        },

        // 用户
        selectUser() {
            let select_user_id_list = [];
            this.select_member_list.forEach((member) => {
                if (member.type == 'user') {
                    select_user_id_list.push(member.id);
                }
            });
            this.$refs.SelectUserModal.start(select_user_id_list, this.could_select_user_id_list);
        },
        finishUserSelect(select_user_id_list) {
            if (this.multiple) {
                let temp_select_member_list = [];
                this.select_member_list.forEach((member) => {
                    /**
                     * 将原本就在并且重新选择完还在的用户id先添加入临时列表，其他类型的不做修改，直接添加
                     * 也就是说将原来列表中有的，新列表中没有的不予保留
                     * 因为要保证原本就在的用户顺序不变，所以这里要麻烦一点
                     * 下面角色、部门选择完成后的操作方式一致，不多赘述，下面举个例子
                     * 原本列表：
                     * u1,u2,r1,d2,r4,d3,u5
                     * 新选择完的用户列表：
                     * u1,u3,u5
                     * 这个方法执行完以后的列表：
                     * u1,r1,d2,r4,d3,u5,u3
                     * 因为u5本身已经在列表中了，所以u3在u5后面
                     */
                    if (member.type == 'user' && select_user_id_list.indexOf(member.id) != -1) {
                        temp_select_member_list.push(member);
                        // 上面添加一个，下面删除一个
                        select_user_id_list.splice(select_user_id_list.indexOf(member.id), 1);
                    } else if (member.type != 'user') {
                        temp_select_member_list.push(member);
                    }
                });
                select_user_id_list.forEach((user_id) => {
                    temp_select_member_list.push({ type: 'user', id: user_id, key: `user${user_id}` });
                });
                this.select_member_list = temp_select_member_list;
            } else {
                if (select_user_id_list.length > 0) {
                    this.select_member_list = [{ type: 'user', id: select_user_id_list[0], key: `user${select_user_id_list[0]}` }];
                }
            }
        },

        // 角色
        selectRole() {
            let select_role_id_list = [];
            this.select_member_list.forEach((member) => {
                if (member.type == 'role') {
                    select_role_id_list.push(member.id);
                }
            });
            this.$refs.SelectRoleModal.start(select_role_id_list, this.could_select_role_id_list);
        },
        finishRoleSelect(select_role_id_list) {
            if (this.multiple) {
                let temp_select_member_list = [];
                this.select_member_list.forEach((member) => {
                    // 操作方法同上方->角色选择完成
                    if (member.type == 'role' && select_role_id_list.indexOf(member.id) != -1) {
                        temp_select_member_list.push(member);
                        select_role_id_list.splice(select_role_id_list.indexOf(member.id), 1);
                    } else if (member.type != 'role') {
                        temp_select_member_list.push(member);
                    }
                });
                select_role_id_list.forEach((role_id) => {
                    temp_select_member_list.push({ type: 'role', id: role_id, key: `role${role_id}` });
                });
                this.select_member_list = temp_select_member_list;
            } else {
                if (select_role_id_list.length > 0) {
                    this.select_member_list = [{ type: 'role', id: select_role_id_list[0], key: `role${select_role_id_list[0]}` }];
                }
            }
        },

        // 部门
        selectDepartment() {
            let select_department_id_list = [];
            this.select_member_list.forEach((member) => {
                if (member.type == 'department') {
                    select_department_id_list.push(member.id);
                }
            });
            this.$refs.SelectDepartmentModal.start(select_department_id_list);
        },
        finishDepartmentSelect(select_department_id_list) {
            if (this.multiple) {
                let temp_select_member_list = [];
                this.select_member_list.forEach((member) => {
                    // 操作方法同上方->角色选择完成
                    if (member.type == 'department' && select_department_id_list.indexOf(member.id) != -1) {
                        temp_select_member_list.push(member);
                        select_department_id_list.splice(select_department_id_list.indexOf(member.id), 1);
                    } else if (member.type != 'department') {
                        temp_select_member_list.push(member);
                    }
                });
                select_department_id_list.forEach((department_id) => {
                    temp_select_member_list.push({ type: 'department', id: department_id, key: `department${department_id}` });
                });
                this.select_member_list = temp_select_member_list;
            } else {
                if (select_department_id_list.length > 0) {
                    this.select_member_list = [{ type: 'department', id: select_department_id_list[0], key: `department${select_department_id_list[0]}` }];
                }
            }
        },
    },
};
</script>

<style scoped>
.flex-button {
    flex-grow: 1;
}
.flex-button:hover {
    flex-grow: 3;
}
.single {
    display: flex;
    justify-content: center;
    align-items: center;
}
.multiple {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    padding: 0px 10px 10px;
}
</style>
