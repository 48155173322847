<template>
    <a-modal title="选择用户" v-model:visible="visible" :width="648" @ok="finish" destroyOnClose>
        <a-transfer class="transfer" :titles="['可选成员', '已选成员']" :listStyle="{ flexGrow: 1, height: '100%' }" :data-source="could_select_user_list" show-search :filter-option="filter" :target-keys="select_user_id_list" @change="change" v-if="multiple">
            <template #render="item">
                <a-avatar :src="item.avatar" size="small"></a-avatar>
                <span class="custom-item" style="margin-left: 5px">{{ item.name }}</span>
            </template>
        </a-transfer>
        <a-radio-group style="display: flex; flex-wrap: wrap; justify-content: flex-start;" v-model:value="select_user_id_list[0]" button-style="solid" v-else>
            <a-radio-button style="margin:8px;border-radius:unset;width:104px;text-align:center;font-family:none;padding:0" :value="user.key" v-for="user in could_select_user_list" :key="user.key">
                <a-avatar style="margin-right:4px" :src="user.avatar" size="small"></a-avatar>
                <span style="line-height:24px;height:40px;vertical-align:middle;">{{ user.name }}</span>
            </a-radio-button>
        </a-radio-group>
    </a-modal>
</template>

<script>
/**
 * 该组件使用方法
 * 1.在父组件中为该组件设置属性：ref='xxx'
 * 2.在父组件中使用this.$refs['xxx'].start()调用该子组件的start方法开始选择
 * 3.监听finish事件，返回的参数为选择的用户id列表，多选时如：[1,2,3]，单选情况也为列表，如：[4]
 */
export default {
    name: 'SelectUserModal',
    data() {
        return {
            visible: false,
            // 可供用于选择的用户列表
            could_select_user_list: [],
            // 已选择的用户id列表
            select_user_id_list: [],
        };
    },
    props: {
        multiple: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        /**
         * @param {[String|Number]} select_user_id_list[] 当前已选中的用户id列表，不传入时默认为空
         * @param {[String|Number]} could_select_user_id_list 可用于选择的用户id列表，不传入时默认为全部用户
         */
        async start(select_user_id_list = [], could_select_user_id_list = undefined) {
            // 用于判断元素是否为数字或字符串数字的函数，[true：0 1 '2' 3.4 '5.6']
            let f1 = (v) => {
                return typeof v === 'number' || (typeof v === 'string' && !isNaN(Number(v)));
            };

            // 将符合f1条件的转为字符串整形数字，[1=>'1'] ['2.3'=>'2'] [4.5=>'4']
            let f2 = (v) => {
                return parseInt(v).toString();
            };

            // 判断select_user_id_list是否合规
            if (!(select_user_id_list instanceof Array)) {
                return console.error('传入的select_user_id_list不是数组');
            }
            // 转化select_user_id_list中的元素
            if (select_user_id_list.every(f1)) {
                select_user_id_list = select_user_id_list.map(f2);
            } else {
                return console.error('传入的select_user_id_list列表中有不合规的元素');
            }

            // 判断是否传入could_select_user_id_list
            if (could_select_user_id_list !== undefined) {
                // 判断could_select_user_id_list是否合规
                if (!(could_select_user_id_list instanceof Array)) {
                    return console.error('传入的could_select_user_id_list不是数组');
                }
                // 转化select_user_id_list中的元素
                if (could_select_user_id_list.every(f1)) {
                    could_select_user_id_list = could_select_user_id_list.map(f2);
                } else {
                    return console.error('传入的could_select_user_id_list列表中有不合规的元素');
                }
            }

            let all_user_list = await this.$api('getUserList');
            if (could_select_user_id_list === undefined) {
                could_select_user_id_list = all_user_list.user_list.map((user) => {
                    return user.id.toString();
                });
            }

            this.could_select_user_list = [];
            all_user_list.user_list.forEach((user) => {
                user.key = user.id.toString();
                user.title = user.name;
                if (could_select_user_id_list.indexOf(user.id.toString()) != -1) {
                    this.could_select_user_list.push(user);
                }
            });

            this.select_user_id_list = [];
            select_user_id_list.forEach((user_id) => {
                if (could_select_user_id_list.indexOf(user_id) != -1) {
                    this.select_user_id_list.push(user_id);
                }
            });
            // 单选模式下，如果已经选择大于1人时，仅取第一人
            if (!this.multiple && this.select_user_id_list.length > 1) {
                this.select_user_id_list = [this.select_user_id_list[0]];
            }

            this.visible = true;
        },
        getUserList() {
            this.$api('getUserList').then((res) => {
                let temp = [];
                res.user_list.forEach((user) => {
                    user.key = user.id.toString();
                    user.title = user.name;
                    temp.push(user);
                });
                this.could_select_user_list = temp;
            });
        },
        filter(input, option) {
            let p1 = this.$pinyin.getCamelChars(option.name).toLowerCase();
            let p2 = this.$pinyin.getFullChars(option.name).toLowerCase();
            let t1 = p1.indexOf(input) != -1;
            let t2 = p2.indexOf(input) != -1;
            let t3 = option.name.indexOf(input) != -1;
            let t4 = option.key.indexOf(input) != -1;
            return t1 || t2 || t3 || t4;
        },
        finish() {
            let temp = [];
            this.select_user_id_list.forEach((item) => {
                temp.push(parseInt(item));
            });
            this.$emit('finish', temp);
            this.visible = false;
        },
        change(target_keys) {
            this.select_user_id_list = target_keys;
        },
    },
};
</script>

<style scoped>
.transfer {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
