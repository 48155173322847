<template>
    <base-content>
        <template #button>
            <a-button type="primary" @click="$message.info(`当前选择的是${{ user: '用户', role: '角色', department: '部门' }[member_type]}：${member_name}`)" v-if="manage_mode == 'member' && member_id">
                <UserOutlined v-if="member_type == 'user'" />
                <TeamOutlined v-if="member_type == 'role'" />
                <ApartmentOutlined v-if="member_type == 'department'" />
                {{ member_name }}
            </a-button>
            <a-button type="primary" @click="selectMember" v-if="manage_mode == 'member'"><AimOutlined />选择成员</a-button>
            <a-button type="primary" @click="selectPermission" v-if="manage_mode == 'permission'"><AimOutlined />选择权限</a-button>
            <!-- <a-button type="primary" @click="changeMode"><SwapOutlined />切换模式</a-button> -->
            <a-button type="primary" @click="saveChange" :disabled="!could_save"><SaveOutlined />保存更改</a-button>
        </template>
        <a-spin class="spin" :tip="spin_text" v-if="show_spin">
            <template #indicator>
                <LoadingOutlined style="font-size:120px;margin-bottom:20px" v-if="spin_loading" />
                <DisconnectOutlined style="font-size:120px;margin-bottom:20px" v-else />
            </template>
        </a-spin>
        <div class="container">
            <div class="member_mode" :class="{ show: manage_mode != 'member' }">
                <div style="height:100%;width:100%;display:flex;flex-direction:row;justify-content:center">
                    <div style="height:100%;width:70%;overflow:auto;padding-left:12px">
                        <a-tree v-if="total_operation_permission_tree.length" checkable :tree-data="total_operation_permission_tree" v-model:checkedKeys="member_operation_permission_list" :selectable="false" @check="could_save = true" defaultExpandAll />
                    </div>
                    <a-divider type="vertical" style="height:100%" />
                    <div style="height:100%;width:70%;overflow:auto;padding-left:20px">
                        <a-checkbox-group style="padding-left:4px;display:block" v-model:value="member_page_permission_list" @change="could_save = true">
                            <a-checkbox style="display:block;padding:7px 0px 3.5px;margin:0" v-for="page in total_page_permission_list" :key="page.page" :value="page.page">{{ page.label }}</a-checkbox>
                        </a-checkbox-group>
                    </div>
                </div>
            </div>
            <div class="permission_mode" :class="{ show: manage_mode != 'permission' }" style="text-align:center">下个版本再做</div>
        </div>
        <SelectMemberModal ref="SelectMemberModal" :multiple="false" @finish="finishSelect" />
        <a-modal v-model:visible="confirm_modal_visible" title="确认更改" @ok="confrimChange" :confirmLoading="confrim_change_loading">
            <div v-if="manage_mode == 'member'">
                <span>正在修改</span>
                <strong>{{ { user: '用户', role: '角色', department: '部门' }[member_type] }} {{ member_name }}</strong>
                <span>的权限，详情如下：</span>
            </div>
            <div style="display:flex;flex-direction:row;justify-content:center;">
                <div style="width:100%;flex-grow:1" v-if="insert_data.data1.length > 0">
                    <div style="text-align:center;margin:8px 0px"><strong>添加</strong>的操作权限</div>
                    <div style="height:100%;overflow:auto">
                        <div v-for="item in insert_data.data1" :key="item.operation" :title="item.label">{{ item.operation }}</div>
                    </div>
                </div>
                <a-divider type="vertical" style="height:unset" v-if="insert_data.data1.length > 0 && delete_data.data1.length + insert_data.data2.length + delete_data.data2.length > 0" />
                <div style="width:100%;flex-grow:1" v-if="delete_data.data1.length > 0">
                    <div style="text-align:center;margin:8px 0px">删除的操作权限</div>
                    <div style="height:100%;overflow:auto">
                        <div v-for="item in delete_data.data1" :key="item.operation" :title="item.label">{{ item.operation }}</div>
                    </div>
                </div>
                <a-divider type="vertical" style="height:unset" v-if="delete_data.data1.length > 0 && insert_data.data2.length + delete_data.data2.length > 0" />
                <div style="width:100%;flex-grow:1" v-if="insert_data.data2.length > 0">
                    <div style="text-align:center;margin:8px 0px"><strong>添加</strong>的页面权限</div>
                    <div style="height:100%;overflow:auto">
                        <div v-for="item in insert_data.data2" :key="item.page" :title="item.label">{{ item.page }}</div>
                    </div>
                </div>
                <a-divider type="vertical" style="height:unset" v-if="insert_data.data2.length > 0 && delete_data.data2.length > 0" />
                <div style="width:100%;flex-grow:1" v-if="delete_data.data2.length > 0">
                    <div style="text-align:center;margin:8px 0px">删除的页面权限</div>
                    <div style="height:100%;overflow:auto">
                        <div v-for="item in delete_data.data2" :key="item.page" :title="item.label">{{ item.page }}</div>
                    </div>
                </div>
                <a-empty style="margin:35px 0 px 10px" v-if="insert_data.data1.length + delete_data.data1.length + insert_data.data2.length + delete_data.data2.length == 0" description="暂无更改"></a-empty>
            </div>
        </a-modal>
    </base-content>
</template>
<script>
import SelectMemberModal from '../components/modal/SelectMember';
import { AimOutlined, DisconnectOutlined, SaveOutlined, UserOutlined, TeamOutlined, ApartmentOutlined, LoadingOutlined, SwapOutlined } from '@ant-design/icons-vue';
export default {
    components: { AimOutlined, DisconnectOutlined, SaveOutlined, SelectMemberModal, UserOutlined, TeamOutlined, ApartmentOutlined, LoadingOutlined, SwapOutlined },
    data() {
        return {
            // 管理模式，member:成员to权限，permission:权限to成员
            manage_mode: 'member',
            // 成员模式下，成员的类型、id、名称
            member_type: '',
            member_id: 0,
            member_name: '请先选择成员',
            // 权限模式下，权限的类型（operation/page）、key、名称
            permission_type: '',
            permission_key: '',
            permission_name: '请先选择权限',
            // 在尚未选择成员或权限的时候，会有一个遮罩层覆盖页面
            show_spin: true,
            // 此处为遮罩层文字
            spin_text: '请先选择成员',
            // 第一个作用是控制页面图标：为true时显示转圈圈图标，false时显示断开连接图标，第二个作用是watch数据变化时不会重复执行getData
            spin_loading: false,
            // 在对页面操作或修改后，置为true，切换模式或保存后，置为false
            could_save: false,
            // 全部页面权限列表，[{label:String,page:String}]
            total_page_permission_list: [],
            // 全部操作权限列表，[{label:String,operation:String}]
            total_operation_permission_list: [],
            // 全部操作权限树，[{title:String,name:String,key:String,children:[{...}]}]
            total_operation_permission_tree: [],
            // 所选成员的操作权限列表，v-model:checkedKeys
            member_operation_permission_list: [],
            // 所选成员的页面权限列表，v-model:checkedKeys
            member_page_permission_list: [],
            // 原始数据，12345分别对应成员的operation、page与权限的user、role、department
            original_data: { data1: [], data2: [], data3: [], data4: [], data5: [] },
            // 控制确认弹窗是否显示
            confirm_modal_visible: false,
            // 控制确认弹窗里的按钮加载状态
            confrim_change_loading: false,
            // 修改确认时用于显示的变更数据，12345与上面对应
            insert_data: { data1: [], data2: [], data3: [], data4: [], data5: [] },
            delete_data: { data1: [], data2: [], data3: [], data4: [], data5: [] },
        };
    },
    mounted() {
        this.$api('getPagePermissionList').then((res) => {
            this.total_page_permission_list = res.page_list;
        });
        this.$api('getOperationPermissionList').then((res) => {
            // 获取树中节点的引用
            let get_tree = function(explode, tree) {
                let temp = tree;
                for (let level = 0; level < explode.length - 1; level++) {
                    for (let i = 0; i < temp.length; i++) {
                        if (temp[i].name == explode[level]) {
                            temp = temp[i].children;
                            break;
                        }
                    }
                }
                return temp;
            };
            // 先按照树的层级分类，能够向下一口气干到到1000层
            let tree_level_list = [[]];
            for (let i = 0; i < 999; i++) {
                tree_level_list.push([]);
            }
            res.operation_list.forEach((item) => {
                let explode = item.operation.split('.');
                let level = explode.length - 1;
                tree_level_list[level].push({ explode, level, title: item.label, key: item.operation, name: explode[level] });
            });
            let tree = [];
            for (let level = 0; level < tree_level_list.length; level++) {
                for (let index = 0; index < tree_level_list[level].length; index++) {
                    let item = tree_level_list[level][index];
                    let temp_tree = get_tree(tree_level_list[level][index].explode, tree);
                    temp_tree.push({ title: item.title, key: item.key, name: item.name, children: [] });
                }
            }
            this.total_operation_permission_tree = tree;
            this.total_operation_permission_list = res.operation_list;
        });
        if (this.$route.query.mode != undefined && this.$route.query.type != undefined && this.$route.query.id != undefined) {
            switch (this.$route.query.mode) {
                case 'member':
                    this.manage_mode = 'member';
                    this.member_id = this.$route.query.id;
                    this.member_type = this.$route.query.type;
                    this.getMemberData();
                    break;
                case 'permission':
                    this.manage_mode = 'permission';
                    this.permission_type = this.$route.query.type;
                    this.permission_key = this.$route.query.key;
                    this.getPermissionData();
                    break;
            }
        }
    },
    methods: {
        // 切换选择模式
        changeMode() {
            switch (this.manage_mode) {
                case 'member':
                    this.manage_mode = 'permission';
                    this.$message.info('模式已切换为 [权限->成员]');
                    this.spin_text = '请先选择权限';
                    this.member_type = '';
                    this.member_id = 0;
                    this.member_name = '请先选择成员';
                    break;
                case 'permission':
                    this.manage_mode = 'member';
                    this.$message.warn('模式已切换为 [成员->权限]');
                    this.spin_text = '请先选择成员';
                    this.permission_type = '';
                    this.permission_key = '';
                    this.permission_name = '请先选择权限';
                    break;
            }
            this.could_save = false;
            setTimeout(() => {
                this.member_operation_permission_list = [];
                this.member_page_permission_list = [];
                this.show_spin = true;
            }, 100);
        },
        // （成员模式）选择成员
        selectMember() {
            if (this.member_type && this.member_id) {
                this.$refs.SelectMemberModal.start([{ type: this.member_type, id: this.member_id }]);
            } else {
                this.$refs.SelectMemberModal.start();
            }
        },
        // （权限模式）选择权限
        selectPermission() {
            this.$message.error('暂不支持');
            /**
             * @todo
             */
        },
        // 完成对（成员或权限）的选择
        finishSelect(member_list = []) {
            this.show_spin = false;
            switch (this.manage_mode) {
                case 'member':
                    this.member_type = member_list[0].type;
                    this.member_id = member_list[0].id;
                    this.getMemberData();
                    break;
                case 'permission':
                    /**
                     * @todo
                     */
                    break;
            }
        },
        // (成员模式)获取成员的权限情况，在成员选择完成后执行
        async getMemberData() {
            this.spin_loading = true;
            let res = await this.$api('getMemberPermission', { member_type: this.member_type, member_id: this.member_id });
            this.member_operation_permission_list = res.operation_list;
            this.member_page_permission_list = res.page_list;
            switch (this.member_type) {
                case 'user':
                    res = await this.$api('getUserInfo', { user_id: this.member_id });
                    this.member_name = res.name;
                    break;
                case 'role':
                    res = await this.$api('getRoleList');
                    res.role_list.forEach((item) => {
                        if (item.id == this.member_id) {
                            this.member_name = item.name;
                        }
                    });
                    break;
                case 'department':
                    res = await this.$api('getDepartmentList');
                    res.department_list.forEach((item) => {
                        if (item.id == this.member_id) {
                            this.member_name = item.name;
                        }
                    });
                    break;
            }
            this.spin_loading = false;
            this.original_data.data1 = this.member_operation_permission_list;
            this.original_data.data2 = this.member_page_permission_list;
        },
        // （权限模式）获取权限的成员情况，在成员选择完成后执行
        async getPermissionData() {
            /**
             * @todo
             */
        },
        saveChange() {
            this.confirm_modal_visible = true;
            switch (this.manage_mode) {
                case 'member':
                    let f = (list, key_name, target_key) => {
                        for (let i = 0; i < list.length; i++) {
                            if (list[i][key_name] == target_key) {
                                return list[i].label;
                            }
                        }
                    };

                    let data1 = this.member_operation_permission_list;
                    let original_data1 = this.original_data.data1;
                    let delete_data1 = [];
                    let insert_data1 = [];
                    data1.forEach((item) => {
                        if (original_data1.indexOf(item) == -1) {
                            insert_data1.push(item);
                        }
                    });
                    original_data1.forEach((item) => {
                        if (data1.indexOf(item) == -1) {
                            delete_data1.push(item);
                        }
                    });
                    this.insert_data.data1 = [];
                    insert_data1.forEach((item) => {
                        this.insert_data.data1.push({ operation: item, label: f(this.total_operation_permission_list, 'operation', item) });
                    });
                    this.delete_data.data1 = [];
                    delete_data1.forEach((item) => {
                        this.delete_data.data1.push({ operation: item, label: f(this.total_operation_permission_list, 'operation', item) });
                    });

                    let data2 = this.member_page_permission_list;
                    let original_data2 = this.original_data.data2;
                    let delete_data2 = [];
                    let insert_data2 = [];
                    data2.forEach((item) => {
                        if (original_data2.indexOf(item) == -1) {
                            insert_data2.push(item);
                        }
                    });
                    original_data2.forEach((item) => {
                        if (data2.indexOf(item) == -1) {
                            delete_data2.push(item);
                        }
                    });
                    this.insert_data.data2 = [];
                    insert_data2.forEach((item) => {
                        this.insert_data.data2.push({ page: item, label: f(this.total_page_permission_list, 'page', item) });
                    });
                    this.delete_data.data2 = [];
                    delete_data2.forEach((item) => {
                        this.delete_data.data2.push({ page: item, label: f(this.total_page_permission_list, 'page', item) });
                    });

                    break;
                case 'permission':
                    break;
            }
        },
        confrimChange() {
            this.confrim_change_loading = true;
            this.$api('saveMemberPermission', {
                member_type: this.member_type,
                member_id: this.member_id,
                operation_permission_list: this.member_operation_permission_list,
                page_permission_list: this.member_page_permission_list,
            }).then((res) => {
                this.confrim_change_loading = false;
                this.confirm_modal_visible = false;
                this.could_save = false;
                this.$message.success('保存完成');
                this.getMemberData();
            });
        },
    },
};
</script>

<style scoped>
.spin {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: calc(100% - 56px);
    opacity: 0.65;
    position: absolute;
    width: 100%;
    top: 56px;
    left: 0px;
    background: white;
    z-index: 9;
}
.container {
    width: 100%;
    height: 100%;
    background-color: #e9e9e9;
}
.member_mode,
.permission_mode {
    background: white;
    width: calc(100% - 20px);
    height: calc(100% - 76px);
    position: absolute;
    backface-visibility: hidden;
    transition: transform 0.9s;
}
.permission_mode.show {
    transform: perspective(2000px) rotateX(180deg);
}
.member_mode {
    transform: perspective(2000px) rotateX(0deg);
}
.permission_mode {
    transform: perspective(2000px) rotateX(0deg);
}
.member_mode.show {
    transform: perspective(2000px) rotateX(-180deg);
}
</style>
