<template>
    <a-modal title="选择部门" v-model:visible="visible" :width="456" @ok="finish" destroyOnClose>
        <a-tree :treeData="could_select_department_tree_list" :replaceFields="{ key: 'id', title: 'name' }" blockNode defaultExpandAll :multiple="multiple" v-model:selectedKeys="select_department_id_list" />
    </a-modal>
</template>

<script>
/**
 * 该组件使用方法
 * 1.在父组件中为该组件设置属性：ref='xxx'
 * 2.在父组件中使用this.$refs['xxx'].start()调用该子组件的start方法开始选择
 * 3.监听finish事件，返回的参数为选择的用户id列表，多选时如：[1,2,3]，单选情况也为列表，如：[4]
 */
export default {
    name: 'SelectDepartmentModal',
    data() {
        return {
            visible: false,
            // 可供用于选择的部门树列表
            could_select_department_tree_list: [],
            // 已选择的用户id列表
            select_department_id_list: [],
        };
    },
    props: {
        multiple: {
            type: Boolean,
            default: true,
        },
    },
    methods: {
        /**
         * @param {[String|Number]} select_department_id_list[] 当前已选中的部门id列表，不传入时默认为空
         * @param {[String|Number]} could_select_department_id_list 可用于选择的部门id列表，不传入时默认为全部用户
         */
        async start(select_department_id_list = [], could_select_department_id_list = undefined) {
            // 用于判断元素是否为数字或字符串数字的函数，[true：0 1 '2' 3.4 '5.6']
            let f1 = (v) => {
                return typeof v === 'number' || (typeof v === 'string' && !isNaN(Number(v)));
            };

            // 将符合f1条件的转为字符串整形数字，[1=>'1'] ['2.3'=>'2'] [4.5=>'4']
            let f2 = (v) => {
                return parseInt(v);
            };

            // 能够递归获取部门树底下所有部门id的函数（前序遍历）
            let f3 = (tree = department_tree, id_list = []) => {
                tree.forEach((item) => {
                    id_list.push(parseInt(item.id));
                    id_list = f3(item.children, id_list);
                });
                return id_list;
            };

            // 能够查找树中id符合的节点，并将符合的树返回
            let f4 = (department_id, tree = department_tree) => {
                for (let i = 0; i < tree.length; i++) {
                    if (tree[i].id == department_id) {
                        return tree[i];
                    }
                    let children_result = f4(department_id, tree[i].children);
                    if (children_result !== false) {
                        return children_result;
                    }
                }
                return false;
            };
            // 判断select_department_id_list是否合规
            if (!(select_department_id_list instanceof Array)) {
                return console.error('传入的select_department_id_list不是数组');
            }
            // 转化select_department_id_list中的元素
            if (select_department_id_list.every(f1)) {
                select_department_id_list = select_department_id_list.map(f2);
            } else {
                return console.error('传入的select_department_id_list列表中有不合规的元素');
            }

            // 判断是否传入could_select_department_id_list
            if (could_select_department_id_list !== undefined) {
                // 判断could_select_department_id_list是否合规
                if (!(could_select_department_id_list instanceof Array)) {
                    return console.error('传入的could_select_department_id_list不是数组');
                }
                // 转化select_department_id_list中的元素
                if (could_select_department_id_list.every(f1)) {
                    could_select_department_id_list = could_select_department_id_list.map(f2);
                } else {
                    return console.error('传入的could_select_department_id_list列表中有不合规的元素');
                }
            }

            let department_tree = (await this.$api('getDepartmentTree')).department_tree;

            // 转换一下could_select_department_id_list，如果没有传入则将其设置为所有部门id
            // 如果传入了，则判断其中每一个元素是否在能够进行选择范围内
            // 该步骤相当于初始化could_select_department_id_list
            if (could_select_department_id_list === undefined) {
                could_select_department_id_list = f3();
            } else {
                let temp1 = f3();
                let temp2 = [];
                could_select_department_id_list.forEach((department_id) => {
                    temp1.indexOf(department_id) != -1 && temp2.indexOf(department_id) == -1 ? temp2.push(department_id) : '';
                });
                could_select_department_id_list = temp2;
            }

            // 转化完could_select_department_id_list后再筛选一下select_department_id_list
            for (let i = select_department_id_list.length - 1; i >= 0; i--) {
                if (could_select_department_id_list.indexOf(select_department_id_list[i]) == -1) {
                    select_department_id_list.splice(i, 1);
                }
            }

            // 将可用于选择的部门id列表，转化为可用于选择的部门树列表
            let could_select_department_tree_list = [];
            while (could_select_department_id_list.length > 0) {
                // 先把id对应的树找出来
                let temp_tree = f4(could_select_department_id_list[0]);
                let new_tree_str = `,${f3([temp_tree]).join(',')},`;
                // 判断新增树与原有列表中树的所属关系
                let flag = true;
                for (let i = could_select_department_tree_list.length - 1; i >= 0; i--) {
                    let old_tree_str = `,${f3([could_select_department_tree_list[i]]).join(',')},`;
                    // 新树在老树中
                    if (old_tree_str.indexOf(new_tree_str) != -1) {
                        flag = false;
                    }
                    // 老树在新树中
                    else if (new_tree_str.indexOf(old_tree_str) != -1) {
                        could_select_department_tree_list.splice(i, 1);
                    }
                }
                // 老树不在原有列表中任意一棵树中
                if (flag) {
                    could_select_department_tree_list.push(temp_tree);
                    f3([temp_tree]).forEach((id) => {
                        let temp_index = could_select_department_id_list.indexOf(id);
                        could_select_department_id_list.splice(temp_index, 1);
                    });
                } else {
                    could_select_department_id_list.splice(0, 1);
                }
            }
            this.could_select_department_tree_list = could_select_department_tree_list;
            this.select_department_id_list = select_department_id_list;
            this.visible = true;
        },
        filter(input, option) {
            let p1 = this.$pinyin.getCamelChars(option.name).toLowerCase();
            let p2 = this.$pinyin.getFullChars(option.name).toLowerCase();
            let t1 = p1.indexOf(input) != -1;
            let t2 = p2.indexOf(input) != -1;
            let t3 = option.name.indexOf(input) != -1;
            let t4 = option.key.indexOf(input) != -1;
            return t1 || t2 || t3 || t4;
        },
        finish() {
            let temp = [];
            this.select_department_id_list.forEach((item) => {
                temp.push(parseInt(item));
            });
            this.$emit('finish', temp);
            this.visible = false;
        },
    },
};
</script>

<style scoped>
.transfer {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>
